import React from 'react';

const Contact = (props) => {

  return (
    <>
      <div id="contactStrate1" className={"strate"}>
        <h1>Contact</h1>
        <div className={"subtitle"}>Besoin d'aide ?</div>

        <div id={"contact_item_wrapper"} className={props.pack}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"contact_link"}><a href={"tel:0974592662"}>09 74 59 26 62</a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel <br className={"d-lg-none"}/>
                local,<br className={"d-lg-block d-none"}/> tarif en vigueur selon opérateur. <br/>
                Ligne active du lundi au vendredi, <br/>
                de 9h30 à 17h30, <br/>
                hors jours fériés)

              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"contact_link"}>
                <a href={"mailto:celebrez-votre-diplome@tlcrewards.com?subject=Opération%20BNP%20Paribas%20-Célébrez%20votre%20diplôme%20/%20Vos%20nom%20et%20prénom"}>celebrez-votre-diplome<br/>@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                (Objet : Opération « BNP Paribas <br className={"d-lg-none"}/>
                -Célébrez <br className={"d-lg-block d-none"}/>votre diplôme » / Vos nom <br className={"d-lg-none"}/>
                et prénom)
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

Contact.defaultProps = {
  pack : "loisir"
}

export default Contact;
