import React from 'react';
//import MixpanelContext from "react-mixpanel/lib/MixpanelContext";

import Contact from "./contact";
const FinOpe = () => {

	//const mixpanel = React.useContext(MixpanelContext);

	return(
		<>
			<div id="strate1" className={"strate first header_global1"}>
				<div className={"visuel"}/>
				<p>L’opération "Le Goût des Vacances"<br className={"d-lg-none"}/> de La Fournée Dorée <br className={"d-lg-block d-none"}/>est désormais<br className={"d-lg-none"}/> terminée.</p>

				<p>Merci à tous pour votre participation !</p>
				<p>Retrouvez toute notre actualité sur<br/> <a href={"https://www.lafourneedoree.fr"} target={"_blank"} className={"bold"} onClick={() => { window.mixpanelhandler.track("Participation_Ended_website link",{"Result Count" : 1}) }}>www.lafourneedoree.fr</a></p>

			</div>

			<Contact/>
		</>
	);
}
export default FinOpe;
